/*loading point*/
.loadingOverlay {
    position: fixed;
}

.apn-public-page-container {
    height: 100vh;
    width: 100%;
    overflow: auto;
}

.apn-public-page-wrapper.container,
.apn-login-page.container {
    height: calc(100% - 25%) !important;
}

.apn-public-page-wrapper .row {
    width: 100%;
}

.apn-login-container,
.apn-forgotpassword-container,
.apn-reset-container,
.successpage-container,
.apn-selectroles-container {
    align-self: center;
}

.apn-login-form button.btn.btn-primary.btn-sm {
    width: 100%;
}

.apn-login-terms-of-use button.freshui-primary-button.btn.btn-primary.btn-sm,
.apn-login-forgot-psw button.freshui-primary-button.btn.btn-primary.btn-sm {
    color: #000;
    background-color: #ffffff;
    border: none;
    padding: 0px;
    text-decoration: underline;
}

.apn-login-form .form_btn,
.apn-forgotpassword-form .form_btn,
.apn-roles-form .form_btn {
    display: none;
}

.nav-logo {
    display: flex;
    width: 0;
    z-index: 9999;
}

.nav-title {
    display: flex;
    width: 100%;
}

.navbar-title img.left-icon {
    --navbar-logo-img-width: 185px;
}

.freshui-navbar-container .dropleft .dropdown-menu {
    top: 100%;
    right: 0;
}

.apn-app-rootlevel-container .freshui-header-top-right-menu {
    align-self: center;
    margin-bottom: 45px;
}

.react-bootstrap-table thead th {
    color: #fff;
    background-color: #212529;
    border-color: #32383e;
    padding-left: 4px !important;
    border: 1px solid #32383e;
    font-weight: normal;
}

.react-bootstrap-table td {
    border: 1px solid #dee2e6;
}

.batches-button button.freshui-primary-button.btn.btn-primary.btn-sm,
.compound-list-added button.freshui-primary-button.btn.btn-primary.btn-sm,
.user-list-added button.freshui-primary-button.btn.btn-primary.btn-sm,
.cmcsite-list-added button.freshui-primary-button.btn.btn-primary.btn-sm,
.site-list-added button.freshui-primary-button.btn.btn-primary.btn-sm,
.study-list-added button.freshui-primary-button.btn.btn-primary.btn-sm,
.subject-button button.freshui-primary-button.btn.btn-primary.btn-sm,
.upload-button button.freshui-primary-button.btn.btn-primary.btn-sm,
.result-download-button button.freshui-primary-button {
    display: flex;
}

.compound-list-table button.freshui-btn-control.btn.btn-outline-primary.btn-sm,
.user-list-table button.freshui-btn-control.btn.btn-outline-primary.btn-sm,
.cmcsite-list-table button.freshui-btn-control.btn.btn-outline-primary.btn-sm,
.site-list-table button.freshui-btn-control.btn.btn-outline-primary.btn-sm,
.study-list-table button.freshui-btn-control.btn.btn-outline-primary.btn-sm {
    display: flex;
    align-items: center;
}

.compound-list-table button.dropdown-toggle.btn.btn-primary,
.user-list-table button.dropdown-toggle.btn.btn-primary,
.cmcsite-list-table button.dropdown-toggle.btn.btn-primary,
.site-list-table button.dropdown-toggle.btn.btn-primary,
.study-list-table button.dropdown-toggle.btn.btn-primary {
    padding: 3px;
}

.apn-login-logo {
    background-color: #f8f9fa !important;
    height: 100px;
    padding: 10px;
    background-color: #ffffff !important;
    border-bottom: 1px solid #dcdcdc;
}

.apn-login-logo .logo-image {
    width: auto;
    height: 80px;
}

.apn-enroll-container {
    margin-top: 15px;
}

.apn-success-button {
    margin-top: 15px;
}

.apn-success-button button.btn.btn-primary {
    padding: 5px 25px;
}

.success-icon {
    padding: 15px;
    width: 80px;
}

.profile-card-view .label-root-contain {
    overflow-wrap: anywhere;
    line-height: 2em;
}

.error-alert-contianer {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    z-index: 9999;
}

.study-list-page .freshui-btn-control,
.user-management-page .freshui-btn-control {
    justify-content: flex-start;
}

.study-list-page .freshui-btn-control.active-btn-danger button.btn.btn-primary.btn-sm,
.user-management-page .freshui-btn-control.active-btn-danger button.btn.btn-primary.btn-sm {
    width: 82px;
}

.study-list-page span.table-action,
.user-management-page span.table-action {
    float: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.select-box small#component-error-text {
    color: red;
}

.freshui-horizontal-navbar-container {
    width: 100%;
    margin-top: 0px;
}

.sidebar-row i.freshui-icons {
    display: none;
}

.sidebar-row ul.freshui-horizontal_ul {
    border-bottom: 1px solid #e3e1e1;
    background: linear-gradient(to top, #ffffff 50%, #ffffff 50%, #ffffff 0%, #e0e2e3 100%)
}

.sidebar-row li.freshui-horizontal-list-item {
    align-items: flex-end;
}

.freshui-horizontal-list-item {
    color: #000;
}

.sidebar-row .freshui-horizontal-sidebar-menu-text {
    font-weight: 600;
    margin-bottom: 5px;
}

.sidebar-row li.freshui-horizontal-list-item.active .freshui-horizontal-list-item {
    color: var(--primaryColor);
    border-bottom: 2px solid var(--primaryColor);
}

.apn-navbar .freshui-navbar-container nav.header-fixed-design.navbar,
.apn-navbar .freshui-navbar-container {
    height: 80px;
    height: 100px;
    padding: 10px;
    background-color: #ffffff !important;
    border-bottom: 1px solid #dcdcdc;
}

.apn-custom-action-button {
    align-items: center;
    justify-content: center;
}

.apn-custom-action-button,
.apnuser-edit-button button.btn.btn-primary.btn-sm {
    display: flex;
    flex-direction: row;
}

.apn-custom-action-button .freshui-btn-control.apnuser-edit-button {
    margin-right: 8px;
}

.inactive-circle,
.active-circle {
    font-size: 18px;
}

.qcButtons .sectionDesktop {
    justify-content: flex-end !important;
}

.label-hidden .text-label {
    display: none;
}

.apn-upload-container .filename-chip {
    text-overflow: ellipsis;
    width: 50%;
    overflow: hidden;
    text-align: left;
}

.apn-upload-form #component-error-text {
    color: red;
}

/*swithc control user*/
.Switch-button.User_edit_switch {
    display: flex;
    margin-bottom: 12px;
    margin-top: 12px;
}

.User_edit_switch .radio_label {
    padding: 0px;
    flex-grow: 0;
}

#component-error-text,
.apn-radioButton .form-text,
.date-component small.form-text,
.radio-group-button small.form-text,
.apn-file-typelabel small.form-text,
.input-textbox small#component-error-text,
.termsofuseCheckbox small.form-text {
    color: red;
}

.freshui-btn-control.dropdown-button-algin {
    justify-content: flex-start;
    align-self: center;
    margin-top: 12px;
    margin-bottom: 0px;
}

.dropdown-button-algin button.btn.btn-primary.btn-sm {
    display: flex;
}

.right-side-lookup-contianer {
    padding: 5px;
    border: 1px solid #dadada;
    border-radius: 0;
}

.apn-link-button .btn-primary,
.apn-link-button .btn-primary:hover {
    background-color: transparent !important;
    border: none !important;
    border-color: transparent !important;
    color: inherit !important;
    text-decoration: underline !important;
    box-shadow: 0 0 0 0.1rem transparent !important;
}

.freshui-btn-control.dropdown-button-algin.add-circule-button button.btn.btn-primary.btn-sm {
    padding: 4px;
}

.icon-button-colum {
    padding-left: 0px;
    padding-right: 0px;
}

.swithc-label-container {
    margin-right: 10px;
}

.toast-root .drawer-z-index {
    z-index: 10000;
}

.link-container a {
    text-decoration: underline;
    margin-left: 10px;
}

.link-container a:hover {
    text-decoration: underline;
    cursor: pointer;
}

.File_Upload_Control .ant-alert-message {
    color: red;
    font-size: 80%;
    padding-left: 10px;
}

/*filterbar */
.apn-filter-row .container {
    max-width: 100%;
    /* margin-bottom: 10px;
    margin-top: 10px; */
}

.apn-filter-row .card-header,
.apn-filter-row .filter-clear-btn {
    display: none;
}

.apn-filter-row .card-body {
    padding: 0px;
}

.apn-filter-row .filter-expanded.card {
    border: 0px;
}

.apn-filter-row .form_btn {
    display: none;
}

.custom-date-dropdown {
    display: flex;
}

.select-option.month {
    margin-left: 12px;
}

.rejectStatusField .form-control {
    color: red;
}

.analyst-form .form_align .row {
    align-items: flex-start !important;
}

.input-textbox.apn-scoring-control {
    display: flex;
    margin-bottom: 12px;
    margin-top: 12px;
    align-items: flex-start;
}

.apn-scoring-control .form-check {
    align-self: center;
}

.apn-scoring-first-textbox {
    display: flex;
    /* width: 50%; */
    width:  192px !important;
    margin-right: 19px
}

.apn-scoring-second-textbox {
    display: flex;
    /* width: 50%; */
    width:  138px;
    margin-left: 14px
}

.apn-scoring-first-textbox label.text-label.form-label {
    display: flex;
    align-self: end;
    min-width: 124px;;
    /* min-width: 120px; */
    max-width: 120px;
    justify-content: flex-end;
    margin-right: 10px;
   
}

/* .apn-scoring-first-textbox input {
    width: 32%;
} */

.apn-scoring-second-textbox label.text-label.form-label {
    display: flex;
    align-self: end;
    max-width: 124px;;
    /* max-width: 120px; */
    min-width: 80px;
}

/* .apn-scoring-second-textbox input {
    width: 30%;
} */

.apn-file-typelabel label.text-label.form-label {
    display: none;
}

.apn-scoring-first-textbox input::-webkit-outer-spin-button,
.apn-scoring-first-textbox input::-webkit-inner-spin-button,
.apn-scoring-second-textbox input::-webkit-outer-spin-button,
.apn-scoring-second-textbox input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.sortable:hover {
    color: #808080 !important;
}

.disabledTextField .form-control {
    pointer-events: none;
    background-color: #e9ecef;
}

.btn-disable .btn {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
}

.apn-fileUploadControl .link-container {
    /* margin-left: 5px */
    text-align: left;
    width: 50%;
}

.link-danger {
    color: #dc3545;
}

.link-success {
    color: #198754;
}

.link-primary {
    color: #0d6efd;
}

.apn-scoring-control.radio-control .apn-scoring-checkbox {
    width: 7%;
}

.qcButtons button {
    color: var(--primaryTextColor);
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
    margin: 5px !important;
}

.toolbar-cancel-btn {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
    box-shadow: 0 0 0 0.1rem #6c757d !important;
}

.guidanceText {
    font-size: small;
    font-weight: lighter;
}

.apn-fileUploadControl.edit-form,
.apn-fileUploadControl.pet-form,
.apn-fileUploadControl.mri-form {
    display: flex;
}

.edit-form .File_Upload_Control {
    width: 50%;
}

.apn-navbar .nav-logo img {
    width: auto;
    height: 80px;
}

.text-avatar-icon {
    cursor: pointer;
}

.ErrorPage-container {
    align-self: center;
}

.selectroles-button,
.selectroles-button button.freshui-primary-button.btn.btn-primary.btn-sm {
    display: block;
    background-color: transparent;
    border: none;
    padding: 0px;
    text-decoration: underline;
    box-shadow: 0 0 0 0.1rem transparent;
    color: #0d6efd;
}

.selectroles-button button.freshui-primary-button.btn.btn-primary.btn-sm:hover {
    cursor: pointer;
}

.selectroles-button .sectionDesktop {
    display: block;
    text-align: -webkit-center;
    text-align: -moz-center;
}

.termsofuseCheckbox {
    display: flex;
}

.termsofuseCheckbox label.required-field {
    margin-right: 10px;
}

.apn-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    background-color: #21374b !important;
    padding: 10px;
    color: #fff;
    font-size: 16px;
}

.apn-footer-link-container {
    color: #fff;
    font-size: 16px;
    line-height: 1.75em;
    border-bottom: 1px solid transparent;
}

.apn-footer-link-container:hover {
    color: #fff;
    font-size: 16px;
    line-height: 1.75em;
    text-decoration: underline;
}

.uploadComments label {
    display: none;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}

.progress-bar {
    background-color: var(--primaryColor);
}

.customDatePickDiv {
    background-color: white;
    border: solid 0.1em #cbd4c9;
    border-radius: 0.25em;
    padding: 0.3em 1.6em 0 1.6em;
}

.activities-management-page .apn-filter-row .form_align .row {
    align-items: baseline !important;
}

.navbarText {
    position: fixed !important;
    width: 96% !important;
    z-index: 5000 !important;
    justify-content: flex-end !important;
    display: flex !important;
}

.navbarText button,
.navbarText button:hover,
.navbarText button:focus,
.navbarText button:active {
    color: #000 !important;
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
    padding-right: 15px !important;
}

.display-none {
    display: none;
}

.display-block {
    display: block;
}

.display-inlineBlock {
    display: inline-block;
}

.display-block button {
    padding: 0px;
}

.Modal-Box {
    z-index: 11000;
}

.drawer-body {
    height: calc(100vh - 100px);
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    box-shadow: var(--primaryHoverColor);
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.sortedColumn {
    color: var(--primaryHoverColor);
    font-weight: bolder;
    stroke-width: 1;
}

.addMoreBtn {
    margin-left: 5px;
}

.image-close-icon {
    margin-left: 10px;
    cursor: pointer;
}

.file-chips-fileName {
    float: left;
    display: flex !important;
    width: 100%;
}

textarea:disabled {
    resize: none;
}

.form-margin-column {
    margin-top: 30px;
    margin-bottom: 30px;
}

.subjectUpload {
    display: inline-block !important;
}

.date-filter-cl-align
{
    margin-top: 15px;
}

.export-button .freshui-icons {
    font-size: 20px;
}
.react-datepicker__input-container{
    width: 100% !important ;
}
.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: -webkit-fill-available;
}

@media (max-width: 829px) {

    .navbarText button,
    .navbarText button:hover,
    .navbarText button:focus,
    .navbarText button:active {
        color: #000 !important;
        border: none !important;
        background: transparent !important;
        box-shadow: none !important;
        padding-right: 2rem !important;
    }
  }
