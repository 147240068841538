:root {
    --backgroundColorforcard: #FFFFFF;
    --primaryColor: #fc751b;
    --primaryHoverColor: #ff6600;
    --primaryTextColor: #FFFFFF;
    --primaryButtonSuccessColor: #3d9f54;
    --primaryButtonDangerColor: #cb3e4c;
    --tableActionButtonColor: #efefef;
    --tableActionButtonHover: black;
    --freshui-drawer-title-font-size: 1.25rem;
    --freshui-drawer-title-font-weight: 750;
}

/* button color*/

.btn-outline-primary,
.btn-primary {
    color: var(--primaryTextColor);
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover,
.btn-outline-primary.focus,
.btn-outline-primary:focus,
.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle,
.show>.btn-primary.dropdown-toggle:focus {
    color: var(--primaryTextColor);
    background-color: var(--primaryHoverColor);
    border-color: var(--primaryColor);
    box-shadow: 0 0 0 0.1rem var(--primaryHoverColor);
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: var(--primaryColor);
    outline: 0;
    box-shadow: var(--primaryHoverColor);
}

/* grid status button color*/

.user-management-page .freshui-btn-control.active-btn-danger button.btn.btn-primary.btn-sm {
    background-color: var(--tableActionButtonColor);
    color: black;
    border-color: var(--tableActionButtonHover);
}

.user-management-page .freshui-btn-control.deactive-btn-success button.btn.btn-primary.btn-sm,
.apnuser-edit-button button.btn.btn-primary.btn-sm {
    background-color: var(--tableActionButtonColor);
    color: black;
    border-color: var(--tableActionButtonHover);
}

.user-management-page .freshui-btn-control.active-btn-danger button.btn.btn-primary.btn-sm:hover,
.apnuser-edit-button button.btn.btn-primary.btn-sm:hover,
.apnuser-edit-button button.btn.btn-primary.btn-sm:active,
.apnuser-edit-button button.btn.btn-primary.btn-sm:focus {
    border-color: var(--tableActionButtonHover);
    box-shadow: 0 0 0 0.1rem var(--tableActionButtonHover);
    background-color: var(--tableActionButtonColor);
    color: black;
}

.user-management-page .freshui-btn-control.deactive-btn-success button.btn.btn-primary.btn-sm:hover {
    border-color: var(--tableActionButtonHover);
    box-shadow: 0 0 0 0.1rem var(--tableActionButtonHover);
}

.page-item.active .page-link {
    color: var(--primaryTextColor);
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
}

.compound-list-table .inactive-circle,
.cmcsite-list-table .inactive-circle,
.site-list-table .inactive-circle, .study-list-table .inactive-circle, .subjects-list-table .inactive-circle,
.apn-user-status.inactive-circle {
    color: var(--primaryButtonDangerColor);
}

.compound-list-table .active-circle,
.cmcsite-list-table .active-circle,
.site-list-table .active-circle, .study-list-table .active-circle, .subjects-list-table .active-circle,
.apn-user-status.active-circle {
    color: var(--primaryButtonSuccessColor);
}